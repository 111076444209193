import { union, literal } from 'io-ts';
import type { TypeOf } from 'io-ts';
import type { ModeName } from './scales';
/**
 * solfege: Do, Re, Mi, etc...
 * scaleDegrees: 1, 2 ... 7, M7, 8
 * names: 'tonic', 'supertonic', etc...
 * handSigns: fist, palm, etc...
 */
export type SolfegePresentationSystem = 'solfege' | 'scaleDegrees' | 'handSigns' | 'names';

export const solfegeSystemNames = ['scaleDegrees', 'solfegeDoMinor', 'solfegeLaMinor'] as const;

export const solfegeSystemDisplayNames: Record<SolfegeSystemName, string> = {
  scaleDegrees: 'Scale Degrees',
  solfegeDoMinor: 'Moveable Do (Do minor)',
  solfegeLaMinor: 'Moveable Do (La minor',
};

/**
 * Valid names for solfege systems
 */
export const SolfegeSystemNameSchema = union([
  literal('scaleDegrees'),
  literal('solfegeDoMinor'),
  literal('solfegeLaMinor'),
]);

export type SolfegeSystemName = TypeOf<typeof SolfegeSystemNameSchema>;

/**
 * The valid scale degree strings '1' through '8'
 */
export const scaleDegrees = ['1', '2', '3', '4', '5', '6', 'M6', '7', 'M7', '8'] as const;
export type ScaleDegree = typeof scaleDegrees[number];
export type MinorScaleDegree = ScaleDegree;
export type MajorScaleDegree = Exclude<ScaleDegree, 'M6' | 'M7'>;

export function isScaleDegree(scaleDegree: string): scaleDegree is ScaleDegree {
  return scaleDegrees.includes(scaleDegree as ScaleDegree);
}

const scaleDegreesTo6 = ['tonic', 'supertonic', 'mediant', 'subdominant', 'dominant', 'submediant'];

export const scaleDegreeLongNames: Record<ModeName, string[]> = {
  Major: scaleDegreesTo6.concat(['leading tone']),
  naturalMinor: scaleDegreesTo6.concat(['subtonic']),
  harmonicMinor: scaleDegreesTo6.concat(['leading tone']),
  melodicMinorAscending: scaleDegreesTo6.concat(['leading tone']),
  melodicMinorDescending: scaleDegreesTo6.concat(['subtonic']),
};

/**
 * The scale degrees in each mode, as an array.
 */
export const scaleDegreesArrayByMode: Record<ModeName | 'allMinors', ScaleDegree[]> = {
  Major: ['1', '2', '3', '4', '5', '6', '7', '8'],
  naturalMinor: ['1', '2', '3', '4', '5', '6', '7', '8'],
  harmonicMinor: ['1', '2', '3', '4', '5', '6', 'M7', '8'],
  melodicMinorAscending: ['1', '2', '3', '4', '5', 'M6', 'M7', '8'],
  melodicMinorDescending: ['8', '7', '6', '5', '4', '3', '2', '1'],
  allMinors: ['1', '2', '3', '4', '5', '6', 'M6', '7', 'M7', '8'],
};

export const scaleDegreeNamesMinor: Record<ScaleDegree, string> = {
  1: 'tonic',
  2: 'supertonic',
  3: 'mediant',
  4: 'subdominant',
  5: 'dominant',
  6: 'submediant',
  M6: 'raised submediant',
  7: 'subtonic',
  M7: 'leading tone',
  8: 'tonic',
};

export const scaleDegreeNamesMajor: Record<ScaleDegree, string> = {
  1: 'tonic',
  2: 'supertonic',
  3: 'mediant',
  4: 'subdominant',
  5: 'dominant',
  6: 'submediant',
  M6: 'submediant',
  7: 'leading tone',
  M7: 'leading tone',
  8: 'tonic',
};

// Solfege Tables:
export const solfegeSystems: {
  Major: {
    values: ScaleDegree[];
    scaleDegrees: Record<ScaleDegree, string>;
    solfegeDoMinor: Record<ScaleDegree, string>;
    solfegeLaMinor: Record<ScaleDegree, string>;
  };
  minor: {
    values: ScaleDegree[];
    scaleDegrees: Record<ScaleDegree, string>;
    solfegeDoMinor: Record<ScaleDegree, string>;
    solfegeLaMinor: Record<ScaleDegree, string>;
  };
} = {
  Major: {
    values: ['1', '2', '3', '4', '5', '6', '7', '8'],
    scaleDegrees: {
      1: '1',
      2: '2',
      3: '3',
      4: '4',
      5: '5',
      6: '6',
      M6: '6',
      7: '7',
      M7: '7',
      8: '1',
    },
    solfegeDoMinor: {
      1: 'Do',
      2: 'Re',
      3: 'Mi',
      4: 'Fa',
      5: 'Sol',
      6: 'La',
      M6: 'La',
      7: 'Ti',
      M7: 'Ti',
      8: 'Do',
    },
    solfegeLaMinor: {
      1: 'Do',
      2: 'Re',
      3: 'Mi',
      4: 'Fa',
      5: 'Sol',
      6: 'La',
      M6: 'La',
      7: 'Ti',
      M7: 'Ti',
      8: 'Do',
    },
  },
  minor: {
    values: ['1', '2', '3', '4', '5', '6', 'M6', '7', 'M7', '8'],
    scaleDegrees: {
      1: '1',
      2: '2',
      3: '3',
      4: '4',
      5: '5',
      6: `${String.fromCharCode(8595)}6`, // "↓6",
      M6: `${String.fromCharCode(8593)}6`, // "↑6",
      7: `${String.fromCharCode(8595)}7`, // "↓7",
      M7: `${String.fromCharCode(8593)}7`, // "↑7"
      8: '1',
    },
    solfegeDoMinor: {
      1: 'Do',
      2: 'Re',
      3: 'Me',
      4: 'Fa',
      5: 'Sol',
      6: 'Le',
      M6: 'La',
      7: 'Te',
      M7: 'Ti',
      8: 'Do',
    },
    solfegeLaMinor: {
      1: 'La',
      2: 'Ti',
      3: 'Do',
      4: 'Re',
      5: 'Mi',
      6: 'Fa',
      M6: 'Fi',
      M7: 'Si',
      7: 'Sol',
      8: 'La',
    },
  },
};

type ChromaticScaleDegreeModifier = '' | '#' | 'b';
type ChromaticScaleDegreeNumber = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8';
export type ChromaticScaleDegree = `${ChromaticScaleDegreeModifier}${ChromaticScaleDegreeNumber}`;

/**
 * Map of the chromatic scale degree in Major to its interval (ascending) from tonic.
 */
// export const majorChromaticScaleDegreesIntervalsFromTonic: Record<ChromaticScaleDegree, string> = {
//   '1': 'P1',
//   '#1': 'A1',
//   b2: 'm2',
//   '2': 'M2',
//   '#2': 'A2',
//   b3: 'm3',
//   '3': 'M3',
//   '#3': 'A3',
//   b4: 'd4',
//   '4': 'P4',
//   '#4': 'A4',
//   b5: 'd5',
//   '5': 'P5',
//   '#5': 'A5',
//   '6': 'M6',
//   'b6': 'm6',
//   '#6'
// };

export const majorChromaticScaleDegrees = [
  'b1',
  '1',
  '#1',
  'b2',
  '2',
  '#2',
  'b3',
  '3',
  '#3',
  'b4',
  '4',
  '#4',
  'b5',
  '5',
  '#5',
  'b6',
  '6',
  '#6',
  'b7',
  '7',
  '#7',
  '8',
] as const;
