/**
 * A filter function for arrays that filters to a list of unique elements,
 * comparing by strict equality.
 * ```typescript
 * [1, '1', 2, 2, 3].filter(isUniqueFilter); // [1, '1', 2, 3]
 * ```
 * @param el
 * @param index
 * @param arr
 * @returns
 */
export function uniqueFilter<T>(el: T, index: number, arr: T[]) {
  return arr.indexOf(el) === index;
}
